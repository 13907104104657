<template>
  <div class="rtb-text-field" :class="layoutClasses">
    <div class="rtb-text-field__label" v-if="label">
      {{ label }}
    </div>

    <div class="rtb-text-field__cnt">
      <input
        v-model="textValue"
        ref="input"
        class="rtb-text-field__input"
        :class="{
          [status]: status,
          [`scribe`]: scribeClass
        }"
        :type="type"
        :pattern="pattern"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @keyup.enter="submit"
      />
      <button
        v-if="buttonText"
        type="button"
        class="rtb-text-field__btn"
        :class="{
          [buttonStatus]: buttonStatus,
          'rtb-text-field__btn--mobile': mobile
        }"
        :disabled="isDisabled"
        @click="submit"
      >
        {{ buttonText }}
      </button>
    </div>

    <div
      v-if="maxTextLength"
      class="rtb-text-field__char-counter"
      :class="{
        'rtb-text-field__char-counter--danger': text.length > maxTextLength
      }"
    >
      Characters left: <b>{{ maxTextLength - text.length }}</b>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"

const SIZES = ["default", "sm"]

export default Vue.extend({
  name: "TextField",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    type: {
      type: [String, Boolean],
      default: undefined
    },
    pattern: {
      type: [String, Boolean],
      default: undefined
    },
    status: {
      type: String,
      default: ""
    },
    wideText: Boolean,
    show: String,
    placeholder: {
      type: String
    },
    buttonText: {
      type: [String, Number]
    },
    maxTextLength: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: "default",
      validator: val => SIZES.includes(val)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    submitable: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: true
    },
    allowFreeSubmit: {
      type: Boolean,
      default: false
    },
    autoFocusOnSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: ""
    }
  },
  mounted() {
    if (this.isScribe && this.autofocus) {
      setTimeout(() => this.focus(), 1500)
    }
  },
  computed: {
    ...mapGetters("Mobile", ["mobile"]),
    textValue: {
      get() {
        return this.text
      },
      set(value) {
        const prevValue = this.text
        this.text = value
        if (this.maxTextLength && value.length > this.maxTextLength) {
          this.text = prevValue
        }

        if (this.$attrs?.type === "number") {
          this.text.replace(/[^0-9.,]/g, "")
        }

        this.$emit("input", this.text)
      }
    },
    isHost() {
      return this.$store.getters.user.role == "facilitator"
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    scribeClass() {
      return this.isScribe && !this.isHost
    },
    layoutClasses() {
      return {
        [this.status]: this.status,
        [this.show]: this.show,
        "rtb-text-field--xl": this.wideText,
        "rtb-text-field--locked": this.isLocked,
        "rtb-text-field--mobile": this.mobile,
        [`rtb-text-field--size--${this.size}`]: this.size !== "default"
      }
    },
    isLocked() {
      return !this.isScribe && !this.isHost && !this.allowFreeSubmit
    },
    isDisabled() {
      return (
        (!this.isScribe && !this.allowFreeSubmit) ||
        this.status === "correct" ||
        this.status === "wrong" ||
        this.disabled
      )
    },
    buttonStatus() {
      return this.text != "" &&
        this.text != null &&
        (this.maxTextLength !== null
          ? this.text.length <= this.maxTextLength
          : true)
        ? "active"
        : "inactive"
    }
  },
  watch: {
    value: {
      handler(value) {
        this.text = value
      },
      immediate: true
    },
    status(newValue) {
      if (newValue === "" || newValue === "correct" || newValue === "wrong") {
        this.$nextTick(() => {
          try {
            this.$refs.input.blur()
          } catch (e) {
            console.log(e.message)
          }
        })
      }
    },
    isScribe(value) {
      if (value && this.autofocus) {
        this.focus()
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    submit() {
      if (this.buttonStatus === "active" && this.submitable) {
        this.$emit("onSubmit", this.text)
        this.text = ""

        if (this.autoFocusOnSubmit) {
          this.focus()
        }
      }
    }
  }
})
</script>

<style lang="scss">
.rtb-text-field {
  $block: &;
  $heihgt: 64px;
  $button-width: 128px;

  &--xl {
    width: 500px;
  }

  &__char-counter {
    font-size: 12px;
    text-align: left;
    height: 0;
    color: #9aa1ad;

    &--danger {
      color: #ff5252;
    }
  }

  &__label {
    line-height: 1;
    margin-bottom: 8px;
    text-align: left;

    #{$block}--mobile & {
      font-size: 12px;
    }
  }

  &__cnt {
    position: relative;
    display: flex;
  }

  &__input {
    height: $heihgt;
    width: 100%;
    height: min(max(36px, 6vh), 60px);
    padding-left: 24px;
    padding-right: 28px;
    border-radius: 20px;
    border: 2px solid #9aa1ad;
    background-color: #f8f9fb;
    font: {
      size: 18px;
      weight: 400;
    }
    color: #393b42;
    pointer-events: none;

    &:placeholder {
      color: #9aa1ad;
    }

    &:focus {
      // TODO: a11y
      outline: 0;
    }

    &.active {
      pointer-events: auto;
    }

    &.correct {
      pointer-events: none;
    }

    &.wrong {
      pointer-events: none;
      border-color: $color-wrong;
      &::placeholder {
        color: $color-wrong;
      }
    }

    &.scribe {
      animation: scribeAnimation 1.5s forwards 1s;
    }
  }

  &__btn {
    flex: 0 0 auto;
    padding-left: 8px;
    padding-right: 8px;
    border-width: 0;
    background-color: $color-primary-accent;
    min-width: $button-width;
    color: #f8f9fb;
    border-radius: 0 20px 20px 0;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
    margin-left: -20px;
    position: relative;

    #{$block}--mobile & {
      min-width: 100px;
      font-size: 13px;
    }

    &:hover {
      background-color: #36309e;
    }

    &:disabled {
      background-color: #595c62;
      color: #868788;
      cursor: auto;
    }
  }

  #{$block}--mobile {
    #{$block}__btn {
      min-width: 100px;
      font-size: 13px;
    }
  }

  &--locked {
    #{$block}__btn {
      display: none;
    }

    #{$block}__input {
      opacity: 0.72;
    }
  }

  &--size {
    &--sm {
      #{$block}__input {
        height: 40px;
        font-size: 16px;
      }

      #{$block}__btn {
        font-size: 18px;
      }
    }
  }
}

.text-input-mission .mission-text-feild.show {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.giphy-mission .mission-text-feild.show {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.line-through input::placeholder {
  text-decoration: line-through;
}

@keyframes scribeAnimation {
  0% {
    box-shadow: none;
  }
  15% {
    box-shadow: 0px 0px 10px 0px $primary_accent_color;
  }
  30% {
    box-shadow: none;
  }
  45% {
    box-shadow: 0px 0px 10px 0px $primary_accent_color;
  }
  60% {
    box-shadow: none;
  }
  75% {
    box-shadow: 0px 0px 10px 0px $primary_accent_color;
  }
  90% {
    box-shadow: none;
  }
}
</style>
